.toggleContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
}

.toggleInput {
  display: none;
}

.toggleSlider {
  width: 45px;
  height: 25px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.3s;
}

.toggleSlider::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: transform 0.3s;
}

.toggleInput:checked + .toggleSlider {
  background-color: #2c7b8c;
}

.toggleInput:checked + .toggleSlider::after {
  transform: translateX(20px);
}

.toggleLabel {
  margin-left: 10px;
  font-size: 14px
}
